'use client'


export function ServerUpdateContext ({
      children
}: {
      children?: React.ReactNode
})
{

      let Timer: string | number | NodeJS.Timeout | undefined
      celog("ServerUpdateContext")
      let counter = 0
      const pipeline = [{ $changeStream: {} }]
      const pipeline2 = [{ $match: { operationType: 'insert' } }]
      const { bfxTransactionList, setBfxTransactionList } = useBfxContext()
      // const sleep = async() => setTimeout(() => {fxlog("sleep counter=",counter++)}, 1000)
      /*     // Server Action
          const [timing, setTiming] = useState<NodeJS.Timeout>()
          useEffect(() =>
          {
                const clientWatch = () => MongoDb.WatchNext({Database:"bo",Collection: "agTransaction",pipeline:pipeline})
    },[]) */





      return null
}
